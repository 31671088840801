<template>
  <v-container fluid class="px-10">
    <!-- Header Section -->
    <v-row class="header-section mb-4">
      <v-col>
        <div class="d-flex align-center">
          <h1 class="text-h4 font-weight-medium grey--text text--darken-3">
            Attachments
          </h1>
          <v-chip
            class="ml-4"
            :color="
              draftEngagementsAttachmentAnalysis.length ? 'primary' : 'grey'
            "
            small
          >
            {{ draftEngagementsAttachmentAnalysis.length }} Results
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <!-- Filters Section -->
    <v-row class="filters-section mb-6">
      <v-col>
        <v-card flat class="pa-4 grey lighten-4">
          <div class="d-flex flex-wrap align-center">
            <v-select
              label="Campaña"
              item-text="name"
              item-value="id"
              dense
              outlined
              clearable
              hide-details
              background-color="white"
              class="filter-field mr-4"
              style="min-width: 200px"
              :items="abstractCampaignOptions"
              v-model="abstractCampaignIdFilter"
              @change="updateFilters"
              prepend-inner-icon="mdi-calendar"
            ></v-select>

            <v-select
              label="Versión de Campaña"
              dense
              outlined
              clearable
              hide-details
              background-color="white"
              class="filter-field mr-4"
              style="min-width: 200px"
              :items="campaignVersionOptions"
              item-text="version"
              item-value="version"
              v-model="attachedCampaignVersionFilter"
              @change="updateFilters"
              :disabled="!abstractCampaignIdFilter"
              prepend-inner-icon="mdi-tag-outline"
            ></v-select>

            <v-autocomplete
              label="Establecimiento"
              outlined
              dense
              hide-details
              background-color="white"
              class="filter-field mr-4"
              style="min-width: 200px"
              v-model="establishmentIdFilter"
              :items="establishments"
              item-text="name"
              item-value="id"
              @change="updateFilters"
              :clearable="true"
              prepend-inner-icon="mdi-map-marker"
            ></v-autocomplete>

            <v-select
              label="Draft Name"
              dense
              outlined
              clearable
              hide-details
              background-color="white"
              class="filter-field mr-4"
              style="min-width: 200px"
              :items="draftNameOptions"
              item-text="draftName"
              item-value="draftEngagementId"
              v-model="draftEngagementIdFilter"
              @change="updateFilters"
              prepend-inner-icon="mdi-file-document-outline"
            ></v-select>

            <v-btn
              small
              color="primary"
              class="ml-auto"
              @click="clearFilters"
              :disabled="!hasActiveFilters"
            >
              <v-icon small>mdi-filter-remove</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Table Section -->
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-data-table
            dense
            :headers="tableHeaders"
            :items="formattedAttachmentData"
            :items-per-page="15"
            :loading="isLoading"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
              'show-current-page': true,
              'show-first-last-page': true,
            }"
            @click:row="handleRowClick"
          >
            <template #loading>
              <v-skeleton-loader
                type="table-row-divider@6"
                class="pa-4"
              ></v-skeleton-loader>
            </template>

            <template #no-data>
              <div class="d-flex flex-column align-center py-6">
                <v-icon size="64" color="grey lighten-1"
                  >mdi-database-off</v-icon
                >
                <span class="grey--text text--darken-1 mt-2"
                  >No attachment data found</span
                >
              </div>
            </template>

            <template #[`item.draftEngagementAssignedHectares`]="{ item }">
              <span :class="getHectaresDiffClass(item)">
                {{ item.draftEngagementAssignedHectares }}
              </span>
            </template>

            <template #[`item.engagementAssignedHectares`]="{ item }">
              <span :class="getHectaresDiffClass(item)">
                {{ item.engagementAssignedHectares }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Navigation Modal -->
    <v-dialog v-model="showNavigationModal" max-width="400">
      <v-card>
        <v-card-title class="primary white--text pa-4">
          <v-icon left color="white">mdi-navigation</v-icon>
          Navigate to
        </v-card-title>

        <v-card-text class="pa-4">
          <div class="subtitle-1 grey--text mb-4">
            {{ selectedRow ? selectedRow.draftName : "Selected Draft" }}
          </div>

          <v-btn
            v-if="selectedRow && selectedRow.draftEngagementId"
            block
            outlined
            color="primary"
            class="mb-4"
            height="80"
            @click="navigateToDraftEngagement"
          >
            <div class="text-left">
              <div class="d-flex align-center mb-1">
                <v-icon left>mdi-file-document-outline</v-icon>
                <span class="font-weight-medium">Draft Engagement</span>
              </div>
              <div class="text-caption grey--text text--darken-1 pl-8">
                View draft details and make changes
              </div>
            </div>
          </v-btn>

          <v-btn
            v-if="selectedRow && selectedRow.attachedEngagementId"
            block
            outlined
            color="primary"
            height="80"
            @click="navigateToEngagement"
          >
            <div class="text-left">
              <div class="d-flex align-center mb-1">
                <v-icon left>mdi-file-document</v-icon>
                <span class="font-weight-medium">Engagement</span>
              </div>
              <div class="text-caption grey--text text--darken-1 pl-8">
                View final engagement details
              </div>
            </div>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey darken-1"
            @click="showNavigationModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Navigation Button -->
    <v-btn
      color="yellow darken-2"
      class="mt-4 white--text"
      @click="redirectToDraftEngagements"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      Volver a Borradores
    </v-btn>
  </v-container>
</template>

<style scoped>
.filter-field {
  max-width: 300px;
}

.header-section {
  border-bottom: 1px solid #e0e0e0;
}

.hectares-mismatch {
  color: #ff5252;
  font-weight: 500;
}

.hectares-match {
  color: #4caf50;
}
</style>

<script>
function convertDateString(dateStr) {
  const [day, month, year] = dateStr.split("/");
  // Month in JavaScript Date is zero-based so we subtract 1
  return new Date(year, month - 1, day);
}

// Function to compare two "DD/MM/YYYY" formatted dates
// Returns: 1 if first date is after second, -1 if before, 0 if equal
function compareDates(dateStr1, dateStr2) {
  const date1 = convertDateString(dateStr1);
  const date2 = convertDateString(dateStr2);

  if (date1.getTime() > date2.getTime()) {
    return 1;
  } else if (date1.getTime() < date2.getTime()) {
    return -1;
  }
  return 0;
}

import { mapGetters } from "vuex";

// Move table headers to a constant
const TABLE_HEADERS = [
  {
    text: "Nombre del Borrador",
    value: "draftName",
    align: "start",
  },
  {
    text: "Establecimiento",
    value: "establishmentName",
  },
  {
    text: "Nombre de Campaña",
    value: "attachedCampaignName",
  },
  {
    text: "Versión de Campaña",
    value: "attachedCampaignVersion",
  },
  {
    text: "Fecha de Creación del Borrador",
    value: "draftEngagementCreatedAt",
    sort: (a, b) => compareDates(a, b),
  },
  {
    text: "Fecha de Creación del Engagement",
    value: "engagementCreatedAt",
    sort: (a, b) => compareDates(a, b),
  },
  {
    text: "Hectáreas del Borrador",
    value: "draftEngagementAssignedHectares",
  },
  {
    text: "Hectáreas del Engagement",
    value: "engagementAssignedHectares",
  },
];

export default {
  name: "DraftEngagementsAttachmentAnalysis",
  props: {
    establishmentId: {
      type: String,
      default: null,
    },
    abstractCampaignId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "abstractCampaigns",
      "draftEngagements",
      "establishments",
      "activities",
      "isDraftEngagementCompareActive",
      "draftEngagementsToCompare",
      "draftEngagementsAttachmentAnalysis",
      "campaigns",
    ]),

    abstractCampaignOptions() {
      return [
        { id: 0, name: "Sin campaña asociada" },
        ...this.abstractCampaigns,
      ];
    },

    draftNameOptions() {
      return this.draftEngagements?.items || [];
    },

    tableHeaders() {
      return TABLE_HEADERS;
    },

    formattedAttachmentData() {
      if (!Array.isArray(this.draftEngagementsAttachmentAnalysis)) {
        return [];
      }

      return this.draftEngagementsAttachmentAnalysis.map((item) => ({
        ...item,
        draftEngagementCreatedAt: this.formatDate(
          item.draftEngagementCreatedAt
        ),
        engagementCreatedAt: this.formatDate(item.engagementCreatedAt),
        draftEngagementAssignedHectares: this.formatNumber(
          item.draftEngagementAssignedHectares
        ),
        engagementAssignedHectares: this.formatNumber(
          item.engagementAssignedHectares
        ),
      }));
    },

    campaignVersionOptions() {
      if (!this.abstractCampaignIdFilter) return [];
      return this.campaigns.filter(
        (c) => c.abstractCampaignId === this.abstractCampaignIdFilter
      );
    },

    hasActiveFilters() {
      return !!(
        this.abstractCampaignIdFilter ||
        this.establishmentIdFilter ||
        this.draftEngagementIdFilter ||
        this.attachedCampaignVersionFilter
      );
    },
  },

  data() {
    return {
      abstractCampaignIdFilter: null,
      attachedCampaignVersionFilter: null,
      draftEngagementIdFilter: null,
      establishmentIdFilter: null,
      isLoading: false,
      showNavigationModal: false,
      selectedRow: null,
    };
  },

  created() {
    document.title = "Attachment Analysis";
    this.isLoading = true;

    // Initialize filters from props if they exist
    if (this.establishmentId) {
      this.establishmentIdFilter = Number(this.establishmentId) || null;
    }
    if (this.abstractCampaignId) {
      this.abstractCampaignIdFilter = Number(this.abstractCampaignId) || null;
    }

    // Load reference data and initial data
    Promise.all([
      this.$store.dispatch("getAbstractCampaigns"),
      this.$store.dispatch("getDraftEngagements"),
      this.$store.dispatch("getEstablishments"),
      this.$store.dispatch("getCampaigns"),
    ])
      .then(async () => {
        const query = this.$route.query;
        const params = {};

        // Only process query params if they weren't set by props
        if (!this.establishmentIdFilter && query.establishmentId) {
          const numValue = Number(query.establishmentId);
          this.establishmentIdFilter = !isNaN(numValue) ? numValue : null;
        }
        if (this.establishmentIdFilter !== null) {
          params.establishmentId = this.establishmentIdFilter;
        }

        if (!this.abstractCampaignIdFilter && query.abstractCampaignId) {
          const numValue = Number(query.abstractCampaignId);
          this.abstractCampaignIdFilter = !isNaN(numValue) ? numValue : null;
        }
        if (this.abstractCampaignIdFilter !== null) {
          params.abstractCampaignId = this.abstractCampaignIdFilter;
        }

        if (query.draftEngagementId) {
          const numValue = Number(query.draftEngagementId);
          this.draftEngagementIdFilter = !isNaN(numValue) ? numValue : null;
          if (this.draftEngagementIdFilter !== null) {
            params.draftEngagementId = this.draftEngagementIdFilter;
          }
        }

        // Handle version 0 case explicitly
        if (query.attachedCampaignVersion !== undefined) {
          const version = Number(query.attachedCampaignVersion);
          this.attachedCampaignVersionFilter = !isNaN(version) ? version : null;
          if (this.attachedCampaignVersionFilter !== null) {
            params.attachedCampaignVersion = this.attachedCampaignVersionFilter;
          }
        }

        await this.$store.dispatch(
          "getDraftEngagementsAttachmentAnalysis",
          params
        );
      })
      .catch((error) => {
        console.error("Error loading initial data:", error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  mounted() {
    document.title = "Attachment Analysis";
  },

  methods: {
    redirectToDraftEngagements() {
      let query = {};
      // Only add to query if value exists and is valid
      if (this.establishmentId && this.establishmentId !== "null") {
        query.establishmentId = this.establishmentId;
      }
      if (this.abstractCampaignId && this.abstractCampaignId !== "null") {
        query.abstractCampaignId = this.abstractCampaignId;
      }

      this.$router.push({
        name: "DraftEngagements",
        query,
      });
    },
    buildFilterParams() {
      const params = {};

      // Only include campaign version if it's not null and is a valid number
      if (this.attachedCampaignVersionFilter !== null) {
        const campaignVersion = Number(this.attachedCampaignVersionFilter);
        if (!isNaN(campaignVersion)) {
          params.attachedCampaignVersion = campaignVersion;
        }
      }

      // Handle other numeric filters
      const otherFilters = {
        establishmentId: this.establishmentIdFilter,
        abstractCampaignId: this.abstractCampaignIdFilter,
        draftEngagementId: this.draftEngagementIdFilter,
      };

      Object.entries(otherFilters).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          const numValue = Number(value);
          if (!isNaN(numValue)) {
            params[key] = numValue;
          }
        }
      });

      return params;
    },
    async clearFilters() {
      this.abstractCampaignIdFilter = null;
      this.establishmentIdFilter = null;
      this.draftEngagementIdFilter = null;
      this.attachedCampaignVersionFilter = null;
      await this.updateFilters();
    },
    getHectaresDiffClass(item) {
      // Return empty string (no color) if either value is missing or "-"
      if (
        !item.draftEngagementAssignedHectares ||
        !item.engagementAssignedHectares ||
        item.draftEngagementAssignedHectares === "-" ||
        item.engagementAssignedHectares === "-"
      ) {
        return "";
      }

      // Only compare and show colors when both values are present
      return Number(item.draftEngagementAssignedHectares) !==
        Number(item.engagementAssignedHectares)
        ? "hectares-mismatch"
        : "hectares-match";
    },
    formatDate(date) {
      if (!date) return "-";
      return new Date(date)
        .toLocaleString("es-AR", {
          timeZone: "America/Argentina/Buenos_Aires",
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .split(",")[0];
    },
    formatNumber(num) {
      if (!num) return "-";
      return Number(num).toLocaleString("en-US");
    },
    async updateFilters() {
      const params = this.buildFilterParams();
      console.log("Filter values:", {
        abstractCampaignId: this.abstractCampaignIdFilter,
        campaignVersion: this.attachedCampaignVersionFilter,
        type: typeof this.attachedCampaignVersionFilter,
      });
      console.log("Built params:", params);

      // Update URL silently without callback
      this.$router.replace({ query: params }).catch(() => {});

      // Log the actual query that will be sent
      const queryString = Object.entries(params)
        .map(([key, value]) => `${key}:${value}`)
        .join(",");
      console.log("Query string that will be sent:", queryString);

      // Fetch data
      await this.$store.dispatch(
        "getDraftEngagementsAttachmentAnalysis",
        params
      );
    },
    handleRowClick(row) {
      console.log("Row data:", row);
      this.selectedRow = row;
      this.showNavigationModal = true;
    },
    navigateToDraftEngagement() {
      this.showNavigationModal = false;
      this.$router.push({
        path: `/draft-engagement/${this.selectedRow.draftEngagementId}`,
      });
    },
    navigateToEngagement() {
      this.showNavigationModal = false;
      this.$router.push({
        path: `/engagement/${this.selectedRow.attachedEngagementId}`,
      });
    },
  },
};
</script>
